<template>
  <!-- 交易日期 -->
  <div class="tradingDatePageBox">
    <!-- 搜索筛选 -->
    <el-form :inline="true" :model="formInline" class="form-search">
      <el-form-item label="调整日期">
        <el-date-picker v-model="formInline.adjustDate" size="small" value-format="yyyy-MM-dd" placeholder="调整日期" />
      </el-form-item>
      <el-form-item label="日期类型">
        <el-select v-model="formInline.dateType" size="small" placeholder="请选择">
          <el-option label="全部" value="" />
          <el-option label="节假日" value="00" />
          <el-option label="工作日" value="10" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" class="high-button" @click="getdata">
          搜索
        </el-button>
        <el-button size="small" class="high-button" @click="getdata('reset')">
          重置
        </el-button>
      </el-form-item>
    </el-form>
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <div class="list-operation">
        <el-button size="small" type="primary" @click="editAndAddRow">
          添加
        </el-button>
      </div>
      <Table
        :item-data="itemData"
        :operation-width="90"
        :list-data="listData"
        :operation-button="operationButton"
        :loading="loading"
      />
      <!-- 分页 -->
      <Pagination :params="formInline" :total="total" :in-article="listData.length" :get-data-list="getdata" />
    </div>
    <!-- 弹出的新增跟修改框 -->
    <Dialog ref="dialog" dialog-width="35%" :edit-form-data="editFormData" :form-item="addAndEditFormItem" @getFormData="getFormData" />
  </div>
</template>

<script>
import Table from '../../components/Table'
import Pagination from '../../components/Pagination2'
import Dialog from '../../components/Dialog'
import { tradeDateList, tradeDateAdd } from '../../api/basicSetting'
export default {
  components: { Table, Pagination, Dialog },
  data() {
    return {
      editFormData: {},
      itemData: [
        { label: '调整日期', prop: 'adjustDate' },
        { label: '日期类型', prop: 'dateType', child: [{ name: '节假日', id: '00' }, { name: '工作日', id: '10' }] },
        { label: '备注', prop: 'remark' }
      ],
      addAndEditFormItem: [
        { label: '调整日期', type: 'dateSelect', value: 'adjustDate', valueFormat: 'yyyy-MM-dd' },
        // { label: '日期类型', type: 'select', optionLabel: 'name', optionId: 'id', value: 'dateType', child: [{ name: '节假日', id: '00' }, { name: '工作日', id: '10' }] },
        { label: '备注', type: 'input', value: 'remark', textarea: true }
      ],
      operationButton: [
        { bType: 'primary', label: '编辑', handleEvent: this.editAndAddRow }
      ],
      loading: false,
      total: 0,
      listData: [],
      formInline: {
        pageSize: 10,
        pageNum: 1
      }
    }
  },
  activated() {
    this.getdata()
  },
  methods: {
    // 获取列表数据
    getdata(type) {
      if (type === 'reset') {
        this.formInline = {
          pageSize: 10,
          pageNum: 1
        }
      } else if (!type) {
        this.formInline.pageNum = 1
      }
      tradeDateList(this.formInline, res => {
        this.listData = [...res.data.pageData]
        this.total = res.data.total
      })
    },
    // 新增或修改数据
    editAndAddRow(row) {
      this.editFormData = { ...row } || {}
      this.$refs.dialog.editFormVisible = true
    },
    // 获取到输入框的内容
    getFormData(value) {
      tradeDateAdd(value, () => {
        this.getdata(value.id)
        this.$message.success('成功！')
        this.$refs.dialog.editFormVisible = false
      })
    }

  }
}
</script>
<style lang="scss">
.tradingDatePageBox {
  .form-search {
    margin-top: 20px;
  }
}
</style>
